@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'OpenSans-Bold';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
  }
  
  @font-face {
    font-family: 'OpenSans-BoldItalic';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic, oblique;
  }
  
  @font-face {
    font-family: 'OpenSans-ExtraBold';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: bolder;
  }
  
  @font-face {
    font-family: 'OpenSans-ExtraBoldItalic';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bolder;
    font-style: italic, oblique;
  }
  
  @font-face {
    font-family: 'OpenSans-Italic';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-Italic.ttf') format('truetype');
    font-style: italic, oblique;
  }
  
  @font-face {
    font-family: 'OpenSans-Light';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: lighter;
  }
  
  @font-face {
    font-family: 'OpenSans-LightItalic';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-LightItalic.ttf') format('truetype');
    font-weight: lighter;
    font-style: italic, oblique;
  }
  
  @font-face {
    font-family: 'OpenSans-SemiBold';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'OpenSans-SemiBoldItalic';
    src: local('OpenSans'), url('/assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic, oblique;
  }
  