$white: #ffffff;
$almost-black: #1f1f1f;

$primary-palette: (
  50:  #e5f5fc, // Table hover
  100: #0073d040, // Table click
  200: #7cbae8,
  300: #00a1e0, // Mitel light blue
  400: #0687f0,
  500: #0073d0, // Mitel primary
  600: #2a65c0,
  700: #2354a0,
  800: #1c437f,
  900: #15325f, // Mitel dark blue,
  contrast: (
    50:  $almost-black,
    100: $almost-black,
    200: $almost-black,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);
$error-palette: (
  50:  #fcdddd,
  100: #f8bcba,
  200: #f59a98,
  300: #f27875,
  400: #ee5753,
  500: #eb3530, // Warning
  600: #cd2b26, // Accessible red
  700: #a4221e,
  800: #7b1a17,
  900: #52110f,
  contrast: (
    50:  $almost-black,
    100: $almost-black,
    200: $almost-black,
    300: $almost-black,
    400: $almost-black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);
$success-palette: (
  50:  #DDF3DF,
  100: #BCE6C0,
  200: #9ADAA0,
  300: #79CE81,
  400: #57C261,
  500: #3FAC49, // Green
  600: #2F9739,
  700: #298632, // Accessible green
  800: #1B5921,
  900: #153918,
  contrast: (
    50:  $almost-black,
    100: $almost-black,
    200: $almost-black,
    300: $almost-black,
    400: $almost-black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);
$alert-palette: (
  50:  #FFF3D8,
  100: #FEE8B0,
  200: #FEDC89,
  300: #FED062,
  400: #FDC53A,
  500: #FDB913,
  600: #C08801,
  700: #B48002,
  800: #876001,
  900: #5A4001,
  contrast: (
    50:  $almost-black,
    100: $almost-black,
    200: $almost-black,
    300: $almost-black,
    400: $almost-black,
    500: $almost-black,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);
$ink-palette: (
  50:  #FFFFFF, // White
  100: #F8F9FB, // Basically white
  200: #EBEFF3, // Super light grey
  300: #D1D6DB, // Light grey
  400: #ACB3B9, // Medium grey
  500: #989DA3,
  600: #777E85,
  700: #595E64,
  800: #464B51, // Dark grey
  900: #1F1F1F, // Almost black
  contrast: (
    50:  $almost-black,
    100: $almost-black,
    200: $almost-black,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);