@use 'sass:math';

$font-family: 'Open Sans', serif;

// Colors
$app-background: #e7eaef;
$white: #ffffff;
$black: #000000;
$border-color: #c7ced9;
$header-font-color: #1f1f1f;

$success: #18a966;
$danger: #eb3530;
$warning: #f7981c;
$primary: #0073d0;
$primary-alpha: rgba(0,115,208, 0.54);
$primary-light: #e5f5fc;

$brand-blue-light: #0081b3;
$brand-blue-dark:  #002856;

$form-input-placeholder-color: #D3D3D3;
$form-input-border: 1px solid #ced4da;
$form-input-color: #495057;
$form-input-label-color: #79797a;
$form-focus-border-color: #2ea2f8;
$form-focus-input-color: #2ea2f8;

$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 600;

$layout-xl-size: 1200px;
$layout-lg-size: 992px;
$layout-md-size: 768px;
$layout-sm-size: 576px;

$container-width: 904px;
$container-horizontal-padding: 48px;

$margin-buffer: 1rem;
$half-margin-buffer: math.div($margin-buffer, 2);

$padding-buffer: 1rem;
$half-padding-buffer: math.div($padding-buffer, 2);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$break-small: 768px;
$nav-closed-width: $break-small;
$navbar-width: 250px;

$form-field-disabled-background-color: #FAFAFA;
$input-disabled-color: rgba(0,0,0,.38);

$mat-icon-disabled-color: #ACB3B9;
$mat-trash-color: #EB3530;

$form-input-disabled-color: #49505761;
$form-checkbox-background-color: #3185FC;
$form-checkbox-background-color-disabled: #D1D6DB;

$table-font-color: #323A45;
$table-even-color: #F1F4F8;

$box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
$border-box: 1px solid $border-color;

$gradient-color: #f2f4f7;
$background-image-stroked-button: linear-gradient(to top, #f2f4f7, white);